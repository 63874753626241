<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { trails } from "@/api/index";
export default {
  data() {
    return {
      numberValidateForm: {
        age: "",
        number: "",
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.trails();
        } else {
          return false;
        }
      });
    },
    async trails() {
      const [name, contact] = [
        this.numberValidateForm.age,
        this.numberValidateForm.number,
      ];
      const { data } = await trails({ name, contact });
      if (data.code === 0) {
        this.$message({
          showClose: true,
          message: "申请成功，我们将会在3个工作日内联系您",
          type: "success",
          // duration:0
        });
      } else {
        this.$message({
          showClose: true,
          message: "网络错误，请稍后重试",
          type: "error",
        });
      }
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

header {
  width: 100%;
  height: 342px;
  background: #edfdfd;
  position: relative;
}

header > img {
  width: 164px;
  height: 134px;
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
}

header > .p1 {
  width: 224px;
  height: 14px;
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: PingFangSC-Regular;
  color: rgba(51, 51, 51, 1);
}

header > .p2 {
  width: 224px;
  height: 14px;
  font-size: 14px;
  text-align: center;
  font-family: PingFangSC-Regular;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(51, 51, 51, 1);
}

header > .p3 {
  width: 224px;
  height: 14px;
  text-align: center;
  position: absolute;
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-family: PingFangSC-Semibold;
}
.demo-ruleForm {
  width: 100%;
  height: 101px;
  position: relative;
}
.demo-ruleForm > .name,
.number {
  width: 295px;
  height: 44px;
  border-radius: 22px;
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.demo-ruleForm > .number {
  position: absolute;
  top: 104%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.el-form-item__content {
  margin-left: 0px !important;
  border-radius: 22px;
}
.el-form-item__content > .el-input > .el-input__inner {
  width: 295px;
  height: 44px;
  border-radius: 22px;
  text-align: center;
}
.demo-ruleForm > .button {
  width: 295px;
  height: 44px;
  position: absolute;
  top: 170%;
  left: 50%;
  border-radius: 22px;
  transform: translate(-50%, -50%);
  background-color: rgba(30, 75, 75, 1);
}
.demo-ruleForm > .button > .el-form-item__content > .el-button {
  width: 295px;
  height: 44px;
  background-color: rgba(30, 75, 75, 1);
  border-radius: 22px;
  border: none;
}
.demo-ruleForm > .button > .el-form-item__content > .el-button > span {
  color: rgba(255, 255, 255, 1);
}
.el-icon-close:before {
  display: none;
}
.el-message {
  min-width: 340px !important;
}
/* flex */
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.justify-countent-between {
  justify-content: space-between;
}
/* button*/
.ButtonOne {
  width: 220px;
  height: 40px;
  background: #1e4b4b;
  border-radius: 20px;
  border: none;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
</style>
