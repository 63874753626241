import axios from 'axios'
const server = axios.create({
  baseURL: '/api',
  timeout: 15000
})

// 添加请求拦截器
server.interceptors.request.use(function (config) {
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
server.interceptors.response.use(function (response) {
  return response
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})

const request = function (option) {
  return server(option)
}

export default request
