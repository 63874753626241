import request from '@/utils/request'

// 试用申请
export function trails(data) {
    return request({
        url: '/user/trails',
        method: 'post',
        data
    })
}
//获取分享活动详情
export function getActive(data) {
    return request({
        url: "/config/common/teaching_activities",
        method: "post",
        data
    })
}
//获取福利详情
export function getBonus(data) {
    return request({
        url: "/config/common/bonus",
        method: "post",
        data
    })
}

//获取资源详情
export function getResource(data) {
    return request({
        url: "/config/common/lesson_plans",
        method: 'post',
        data
    })
}
export function getValidateCode(data) {
    return request(
        {
            headers: {
                "Content-Type": "application/json",
            },
            url: "/user/code",
            method: "post",
            data
        })
}
//获取选项校验码
export function getCaptcha(params) {
    return request({
        url: "user/code/captcha",
        method: "get",
        params
    })
}

//获取会员卡
export function getMember(data) {
    return request({
        url: "/user/register/activity",
        method: "post",
        data
    })
}

//落地页-验证码发送
export function getPhoneCode(params) {
    return request({
        url: "/admin/trial/code",
        method: "get",
        params
    })
}

//落地页-验证码验证
export function cheakCode(params) {
    return request({
        url: "/admin/trial/code/check",
        method: "get",
        params
    })
}




//落地页-申请试用
export function sendUse(data) {
    return request({
        url: "/admin/trial",
        method: "post",
        data
    })
}