import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      title: '申请试用'
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
    meta: {
      title: '向知平台学习指南'
    },
  },
  {
    path: '/finance',
    name: 'Finance',
    component: () => import('@/views/finance.vue'),
  },
  {
    path: '/finance2',
    name: 'Finance2',
    component: () => import('@/views/finance2.vue'),
  },
  {
    path: '/pbl',
    name: 'Pbl',
    component: () => import('@/views/pbl.vue'),
  },
  {
    path: '/quill',
    name: 'quill',
    component: () => import('@/views/quill.vue'),
  },
  {
    path: '/AbilityTraining',
    name: 'AbilityTraining',
    component: () => import('@/views/AbilityTraining.vue'),
    meta: {
      title: '向知能力培养体系'
    }
  },
  {
    path: '/TrainingCamp',
    name: 'TrainingCamp',
    component: () => import('@/views/TrainingCamp.vue'),
    meta: {
      title: '向知能力培养训练营︱活动总结与精彩回顾'
    }
  },
  {
    path: '/HuDa',
    name: 'HuDa',
    component: () => import('@/views/HuDa.vue'),
    meta: {
      title: '向知X湖大｜走进《周易》的世界'
    }
  },
  {
    path: '/StudyGuide',
    name: 'StudyGuide',
    component: () => import('@/views/StudyGuide.vue'),
    meta: {
      title: '学习指南'
    }
  },
  {
    path: '/UserAtlas',
    name: 'UserAtlas',
    component: () => import('@/views/UserAtlas.vue'),
    meta: {
      title: '用户图鉴'
    }
  },
  {
    path: '/HeaderTop',
    name: 'HeaderTop',
    component: () => import('@/views/Layout/headTop.vue'),
    meta: {
      title: '封面'
    }
  },
  {
    path: '/FooterBottom',
    name: 'FooterBottom',
    component: () => import('@/views/Layout/footerBottom.vue'),
    meta: {
      title: '封底'
    }
  },
  {
    path: '/Semester',
    name: 'Semester',
    component: () => import('@/views/ReactHtml/semester.vue'),
    meta: {
      title: '学期学情报告'
    }
  },
  {
    path: '/Monthly',
    name: 'Monthly',
    component: () => import('@/views/ReactHtml/monthly.vue'),
    meta: {
      title: '月度学情报告'
    }
  },
  {
    path: '/RankingList',
    name: 'RankingList',
    component: () => import('@/views/ReactHtml/rankingList.vue'),
    meta: {
      title: '学习排行榜'
    }
  },
  {
    path: '/getMember',
    name: 'getMember',
    component: () => import('@/views/getMember.vue'),
    meta: {
      title: '加入教研室'
    },
    children: [
      {
        path: "activity",
        meta: {
          keepAlive: true,
          left: true,
          crumbs: false,
          title: '加入教研室'
        },
        component: resolve => require(['@/components/courseCover.vue'], resolve)
      }, {
        path: "limitWelfare",
        meta: {
          keepAlive: true,
          left: true,
          crumbs: false,
          title: '加入教研室'
        },
        component: resolve => require(['@/components/limitedWelfare.vue'], resolve)
      },
      {
        path: "getResource",
        meta: {
          keepAlive: true,
          left: true,
          crumbs: false,
          title: '加入教研室'
        },
        component: resolve => require(['@/components/resource.vue'], resolve)
      }
    ]
  },
  {
    path: '/cardPwd',
    name: 'cardPwd',
    component: () => import('@/views/cardPwd.vue'),
    meta: {
      title: '成员卡'
    }
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('@/views/Persimmon/share.vue'),
    meta: {
      title: '课程详情'
    }
  },
   {
    path: '/courseOutside',
    name: 'courseOutside',
    component: () => import('@/views/Web/course.vue'),
    meta: {
      title: '向知学习平台'
    }
  },
  {
    path: '/chapterOutside',
    name: 'chapterOutside',
    component: () => import('@/views/Web/chapters.vue'),
    meta: {
      title: '向知学习平台'
    }
  },
  {
    path: '/courseChart',
    name: 'courseChart',
    component: () => import('@/views/chart/courseChart.vue'),
    meta: {
      title: '资源收集器'
    }
  },
  ]
})
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})







export default router
